/*Spartan*/
@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-Regular.ttf');
  src: url('./Spartan/Spartan-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-Black.ttf');
  src: url('./Spartan/Spartan-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-Bold.ttf');
  src: url('./Spartan/Spartan-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-ExtraBold.ttf');
  src: url('./Spartan/Spartan-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-Light.ttf');
  src: url('./Spartan/Spartan-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-ExtraLight.ttf');
  src: url('./Spartan/Spartan-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-Medium.ttf');
  src: url('./Spartan/Spartan-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-SemiBold.ttf');
  src: url('./Spartan/Spartan-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Spartan';
  src: url('./Spartan/Spartan-Thin.ttf');
  src: url('./Spartan/Spartan-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}


/*League Spartan*/
@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-Regular.ttf');
  src: url('./League-Spartan/LeagueSpartan-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-Black.ttf');
  src: url('./League-Spartan/LeagueSpartan-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-Bold.ttf');
  src: url('./League-Spartan/LeagueSpartan-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-ExtraBold.ttf');
  src: url('./League-Spartan/LeagueSpartan-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-Light.ttf');
  src: url('./League-Spartan/LeagueSpartan-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-ExtraLight.ttf');
  src: url('./League-Spartan/LeagueSpartan-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-Medium.ttf');
  src: url('./League-Spartan/LeagueSpartan-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-SemiBold.ttf');
  src: url('./League-Spartan/LeagueSpartan-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./League-Spartan/LeagueSpartan-Thin.ttf');
  src: url('./League-Spartan/LeagueSpartan-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}
