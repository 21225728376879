/* Top component */
.react-datepicker__tab-loop {
    z-index: 4 !important;
}

/* Wrapper component */
.react-datepicker{
    border: none !important;
    box-shadow: 1px 1px 30px 30px #00000005 !important;
    border-radius: 8px !important;
    display: flex !important;
    flex-direction: column !important;
    font-family: Spartan !important;
}

/* Header component */
.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
    background-color: #FFFFFF !important;
    border: none !important;
}

/* Days style */
.react-datepicker__day--keyboard-selected{
    background-color: #FFFFFF !important;
    border-radius: 100px !important;
}
.react-datepicker__day:hover{
    border-radius: 100px !important;
    background-color: #ebebeb !important;
}
.react-datepicker__day--selected{
    background-color: #A3DA58 !important;
    border-radius: 100px !important;
}
.react-datepicker__day--selected:hover{
    background-color: #A3DA58 !important;
    border-radius: 100px !important;
}

.react-datepicker__day{
    color: #313131 !important;
}

.react-datepicker__day--disabled{
    cursor: default !important;
    color: #B5B5B5 !important;
}

.react-datepicker__day--today{
    font-weight: 400 !important;
}
